import $ from "jquery";
import "popper.js"; // Bootstrap requires this
import "bootstrap";
import "fslightbox";
import "slick-carousel";
import Player from "@vimeo/player";

//import "./js/fslightbox"; // Pro version 
// TODO Pro version import not working, need for captions
// added manually for now

// Can import modules as needed
// ex. import 'bootstrap/js/dist/dropdown';
// import "./scss/style.scss"; // This is now being imported in the webpack.config file 

// This is for slick carousels
global.jQuery = global.$ = require('jquery');

// # ---------------------------------------------------------------
// # Custom Methods...
(function ($) {

	// Header scrolled
	// Check scroll amount, show / hide header items accordingly
	// function checkScrolled() {

	// 	let $elem = $("header#site-header");

	// 	if ($elem.length) {
	// 		let $window = $(window);
	// 		let docViewTop = $window.scrollTop();
	// 		let docViewBottom = docViewTop + $window.height();
	// 		let elemTop = $elem.offset().top;
	// 		let elemBottom = elemTop + $elem.height();

	// 		if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
	// 			// $(".lg-hide-on-scrolled").each(function () {
	// 			//   $(this).fadeIn(250);
	// 			// });
	// 			// $(".lg-show-on-scrolled").each(function () {
	// 			//   $(this).fadeOut(1);
	// 			// });
	// 			$("nav.fixed-top").removeClass("navbar-scrolled");
	// 			//$(".custom-logo-link").removeClass("scrolled");
	// 		} else {
	// 			// $(".lg-hide-on-scrolled").each(function () {
	// 			//   $(this).fadeOut(1);
	// 			// });
	// 			// $(".lg-show-on-scrolled").each(function () {
	// 			//   //$(this).fadeIn(250);
	// 			//   $(this).removeClass("d-none");
	// 			//   $(this).fadeIn(1);
	// 			// });
	// 			$("nav.fixed-top").addClass("navbar-scrolled");
	// 			//$(".custom-logo-link").addClass("scrolled");
	// 		}
	// 	}
	// }

	// Shrink header on scroll OR page load
	// $(window).scroll(function () {
	// 	checkScrolled();
	// });

	// $(document).ready(function () {
	// 	checkScrolled();
	// });

	// Slick sliders
	$(function () {
		$('.logo-slider').slick({
			slidesToShow: 7,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			autoplay: true,
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-right"></i></i></a>',

			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
					}
				},
			],
		});
	});

	// Set carousel height to the tallest slide to 
	// prevent content shifting
	function setCarouselHeights() {
		$('.carousel-inner--voices').each(function () {
			var heights = [];
			$(this).children('.carousel-item').each(function () {
				heights.push($(this).height());
			});

			$(this).children('.carousel-item').height(Math.max(...heights));
		});
	}
	setCarouselHeights();

	// Slick sliders
	// Text carousel
	$(function () {
		$('.text-carousel--2-col').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: true,
			autoplay: false,
			// appendArrows: '.slick-arrow-wrap',
			prevArrow:
				'<a class="slick-prev"><span class="bi bi-arrow-left-short" aria-hidden="true"></span></a>',
			nextArrow:
				'<a class="slick-next"><span class="bi bi-arrow-right-short" aria-hidden="true"></span></a>',
			responsive: [
				{
					breakpoint: 530,
					settings: {
						slidesToShow: 1,
					}
				},
			],
		});
		$('.text-carousel--4-col').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: true,
			autoplay: false,
			// appendArrows: '.slick-arrow-wrap',
			prevArrow:
				'<a class="slick-prev"><span class="bi bi-arrow-left-short" aria-hidden="true"></span></a>',
			nextArrow:
				'<a class="slick-next"><span class="bi bi-arrow-right-short" aria-hidden="true"></span></a>',

			responsive: [
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 720,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 530,
					settings: {
						slidesToShow: 1,
					}
				},
			],
		});
		// Image Carousel
		$('.image-carousel--slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: true,
			autoplay: false,
			centerMode: true,
			variableWidth: true,
			//appendArrows: '.slick-nav',
			prevArrow:
				'<a class="slick-prev"><span class="bi bi-arrow-left-short" aria-hidden="true"></span></a>',
			nextArrow:
				'<a class="slick-next"><span class="bi bi-arrow-right-short" aria-hidden="true"></span></a>',
		});

	});

	// Add class to header when mobile nav is open
	$('.navbar-toggler').click(function () {
		$('header#site-header').toggleClass('nav-open');
		$('body').toggleClass('overflow-hidden');
	});

	// Toggle class on top level menu items when they are clicked
	$('li.menu-item').click(function () {
		$('li.menu-item').not(this).each(function () {
			$(this).removeClass('active');
		});
		$(this).toggleClass('active');
	});

	// Toggle class on #header-nav when menu item is opened or closed
	// this is to hide/show the other menu items on mobile
	$('li.menu-item-has-children').click(function () {
		$('#header-nav').toggleClass('nav-item-open');
	});

	// Stop propogation when dropdown items are clicked
	// to prevent menu change while page is loading
	$('li.menu-item-has-children a.dropdown-item').click(function (e) {
		e.stopPropagation();
	});
	$('li.menu-item-has-children a.no-toggle').click(function (e) {
		e.stopPropagation();
	});
	


	$(window).scroll(function () {

		//let fixed = $(".fixed-column .img-wrap");
		if ($(".fixed-column").length > 0) {

			let fixed_img_pos = $(".fixed-column .img-wrap").offset().top;
			//let fixed_height = $(".fixed-column .img-wrap").height();

			for (let x = 0; x < $('.fixed-column .content-container').children().length; x++) {

				// let toCross_position = $(".fixed-column .content-wrap.content-" + x).offset().top - 200;

				let toCross_position = $(".fixed-column .content-wrap.content-" + x).offset().top - $('.fixed-column .img-wrap').height();

				let toCross_height = $(".fixed-column .content-wrap.content-" + x).height();

				let $new_src = '';
				let $current_src = $('.fixed-column .img-wrap > img').attr("src");


				// TODO
				// if (!(fixed_img_pos < toCross_position) && !(fixed_img_pos > toCross_position + toCross_height)) {

				// Trigger the change when the top of the text column starts to enter the middle of the viewpoert
				// If the offset of the current column minus amount scrolled is less than half of the window height or greater than the same - 10 ( 10px window for change )
				if (($(".fixed-column .content-" + x).offset().top - $(window).scrollTop()) < (($(window).height() / 2) - 80) && ($(".fixed-column .content-" + x).offset().top - $(window).scrollTop()) > (($(window).height() / 2) - 100)) {

					// Prevent the fade in / out from repeating
					$new_src = $('.content-wrap.content-' + x).data('img');

					if ($new_src !== $current_src) {
						$('.fixed-column .img-wrap > img').fadeOut(100);
						$('.fixed-column .img-wrap > img').attr("src", $('.content-wrap.content-' + x).data('img'));
						$('.fixed-column .img-wrap > img').fadeIn(300);

						$('.content-wrap hr.intro-hr').each(function () {
							$(this).removeClass('md-up-teal')
						});
						$('.content-wrap h4.intro-h4').each(function () {
							$(this).removeClass('md-up-teal')
						});

						$('.content-wrap.content-' + x + ' hr.intro-hr').addClass('md-up-teal');
						$('.content-wrap.content-' + x + ' h4.intro-h4').addClass('md-up-teal');

					}

				}

			}
		}

	});

	// Update News Search placeholder text on mobile
	$('#news-stories-search input#ns-search').each(function () {
		$(this).data('placeholder', $(this).attr('placeholder'));
	});


	function changePlaceholder() {
		if ($(window).width() < 600) {
			$('#news-stories-search input#ns-search').attr('placeholder', 'Search');
		} else {
			$('#news-stories-search input#ns-search').each(function () {
				$(this).attr('placeholder', $(this).data('placeholder'));
			});
		}
	}

	$(window).resize(changePlaceholder).trigger('resize');


	// Fixed Column Block 
	// Set last item to have min height equal to image height

	function fixedColBlockMinHeight() {
		$(".fixed-column .content-container").each(function () {
			$(this).children('.content-wrap:last-of-type').each(function () {
				if ($(window).width() > 600) {
					$(this).css('min-height', $(this).attr('data-img-height') + 'px');
				} else {
					$(this).css('');
				}
			});
		});
	}

	$(window).resize(fixedColBlockMinHeight).trigger('resize');


	// Gravity forms
	// Material label effect
	// Inputs
	$('.gfield .ginput_container input').focus(function () {
		$(this).parents('.gfield').addClass('focus');
	});
	$('.gfield .ginput_container input').blur(function () {
		if ($(this).val()) {
			$(this).parents('.gfield').addClass('has-content');
		} else {
			$(this).parents('.gfield').removeClass('has-content');
			$(this).parents('.gfield').removeClass('focus');
		}
	});
	// Text area
	$('.gfield .ginput_container textarea').focus(function () {
		$(this).parents('.gfield').addClass('focus');
	});
	$('.gfield .ginput_container textarea').blur(function () {
		if ($(this).val()) {
			$(this).parents('.gfield').addClass('has-content');
		} else {
			$(this).parents('.gfield').removeClass('has-content');
			$(this).parents('.gfield').removeClass('focus');
		}
	});
	// Select
	$('.gfield .ginput_container select').focus(function () {
		$(this).parents('.gfield').addClass('focus');
	});
	$('.gfield .ginput_container select').blur(function () {
		if ($(this).val()) {
			$(this).parents('.gfield').addClass('has-content');
		} else {
			$(this).parents('.gfield').removeClass('has-content');
			$(this).parents('.gfield').removeClass('focus');
		}
	});

	// Check form fields again on page load
	$(document).ready(function () {
		$('.gfield .ginput_container input').each(function () {
			if ($(this).val()) {
				$(this).parents('.gfield').addClass('has-content');
			} else {
				$(this).parents('.gfield').removeClass('has-content');
				$(this).parents('.gfield').removeClass('focus');
			}
		});
		// Text area
		$('.gfield .ginput_container textarea').each(function () {
			if ($(this).val()) {
				$(this).parents('.gfield').addClass('has-content');
			} else {
				$(this).parents('.gfield').removeClass('has-content');
				$(this).parents('.gfield').removeClass('focus');
			}
		});
		// Select
		$('.gfield .ginput_container select').each(function () {
			if ($(this).val()) {
				$(this).parents('.gfield').addClass('has-content');
			} else {
				$(this).parents('.gfield').removeClass('has-content');
				$(this).parents('.gfield').removeClass('focus');
			}
		});
		return error;
	});

	// 
	// END Material label effect

	// Custom vimeo play
	// Using the Vimeo Player SDK
	//
	$(".video-play").click(function () {
		var player = new Player($(this).siblings(".embed-container").children('iframe'));
		player.play();
		$(this).hide();
		$(this).siblings('.video-pause').show();
	});

	$(".video-pause").click(function () {
		var player = new Player($(this).siblings(".embed-container").children('iframe'));
		player.pause();
		$(this).hide();
		$(this).siblings('.video-play').show();

	});

	// Position slick arrows 
	function positionSlickArrows() {
		if ($(window).width() > 599) {
			$('.text-carousel').each(function () {

				$(this).find('a.slick-prev').css('left', '50%');
				$(this).find('a.slick-next').css('left', '50%');

				$(this).find('a.slick-prev').css('transform', 'translateX(-50%)');
				$(this).find('a.slick-next').css('transform', 'translateX(-50%)');

				if ($(window).width() > 1400) {
					$(this).find('a.slick-prev').css('transform', 'translateX(-' + ( $(this).find('ul.slick-dots').width() / 2 + 100) + 'px');
					$(this).find('a.slick-next').css('transform', 'translateX(+' + ( $(this).find('ul.slick-dots').width() / 2 + 50) + 'px');
				}else{
					$(this).find('a.slick-prev').css('transform', 'translateX(-' + ( $(this).find('ul.slick-dots').width() / 2 + 70) + 'px');
					$(this).find('a.slick-next').css('transform', 'translateX(+' + ( $(this).find('ul.slick-dots').width() / 2 + 30) + 'px');
				}
			});
		}
	}

	$(window).resize(positionSlickArrows).trigger('resize');

	// Document Ready
	//
	$(document).ready(function () {

		positionSlickArrows();

		// Loop through each embed 
		$(".embed-wrap--custom-play").each(function () {

			const wrapper = $(this);
			var player = new Player(wrapper.children(".embed-container").children('iframe'));

			player
				.getVideoId()
				.then(function (id) {
					// id = the video id
					// we will use this in a classname
					const stringID = "video-" + id.toString() + Math.floor(Math.random() * 100);

					// Add the class to to the wrapper and the play button
					// so that we can hide only the button associated with
					// this video.
					wrapper.addClass(stringID);
					wrapper.children(".video-play").addClass(stringID);
					wrapper.children(".video-pause").addClass(stringID);
					wrapper.children(".embed-container").children('iframe').attr('video-id', stringID);
					// wrapper.data('video-id', stringID);
					// wrapper.children(".video-play").data('video-id', stringID);
					$(this).play();

				})
				.catch(function (error) {
					// an error occurred
				});

			// When the video is played from the default controls
			player.on("play", function () {
				player
					.getVideoId()
					.then(function (id,) {

						// Get parent wrapper 
						// Do things with it

						// Create the same string ID
						const stringID = player.element.getAttribute('video-id');

						// And hide the button with the matching class
						$(".video-play." + stringID).hide();
						$(".video-pause." + stringID).show();

					})
					.catch(function (error) {
						// an error occurred
					});
			});
		});
		// END Custom vimeo play

		fixedColBlockMinHeight();

	});

})($);